<template>
  <div class="w-80 bg-white border border-lunar rounded-[10px] shadow-misty-veil flex flex-col">
    <div class="flex gap-3 py-2.5 px-4 border-b border-lunar items-center">
          <span class="rounded-full w-9 h-9 flex justify-center items-center bg-primary">
            <img class="img--white !w-[18px] !h-[18px]" src="../../../../assets/icons/screencast.svg" alt="">
          </span>
      <p class="text-13 font-medium text-base" v-html="notifications[notifications.length - 1].text" />
    </div>
    <button @click="emit('handleOpenFullList')" class="text-primary text-12 font-semibold py-2.5">
      Еще {{ notifications.length - 1 }} уведомлений
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  notifications: {
    date: string,
    text: string
  }[],

}>()
const emit = defineEmits<{
  (e: 'handleOpenFullList'): void
}>()
</script>

<style scoped lang="scss">

</style>
