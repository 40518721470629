<template>
  <div
    class="w-[460px] bg-white border border-lunar rounded-[10px] shadow-misty-veil flex flex-col mr-[78px] max-h-[600px] overflow-y-auto"
  >
    <div class="flex items-center justify-between px-3 py-4 border-b border-lunar">
      <p class="text-14 font-semibold text-base">
        Уведомления <span class="text-hoar">{{ notifications.length }}</span>
      </p>
      <button @click="emit('handleNotificationsDialogOpen')">
        <img class="img--hoar !w-[18px] !h-[18px]" src="../../../../assets/icons/direction/arrow-right-simple.svg" alt="">
      </button>
    </div>
    <div class="px-3 py-4">
      <app-tabs v-model="activeTab" :items="TABS" no-query />
    </div>
    <div class="flex flex-col px-2 pb-2">
      <div v-for="(notification, index) in notifications" :key="index" class="notification-item group">
        <div class="py-2">
              <span class="rounded-full w-9 h-9 flex justify-center items-center bg-primary ml-2">
              <img class="img--white !w-[18px] !h-[18px]" src="../../../../assets/icons/screencast.svg" alt="">
            </span>
        </div>
        <div class="flex flex-col w-full">
          <hr
            v-if="index > 0"
            class="-mr-2 mt-auto group-hover:opacity-0 border-lunar"
          />
          <div class="flex w-full justify-between pr-2 py-2">
            <div>
              <p class="text-11 font-medium text-hoar">
                {{ notification.date }}
              </p>
              <p class="text-13 font-medium text-base line-clamp-3" v-html="notification.text" />
            </div>
            <div class="flex flex-col items-center justify-center shrink-0 relative">
              <img
                class="img--hoar w-[18px] h-[18px] opacity-0 group-hover:opacity-100"
                src="../../../../assets/icons/direction/arrow-right-simple.svg" alt=""
              >
              <span class="rounded-full w-1.5 h-1.5 bg-primary group-hover:opacity-0 absolute" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AppTabs from "@/shared/UIKit/app-tabs/app-tabs.vue";
import { ref } from "vue";
import { TABS } from "@/layouts/headers/components/notifications-popover/notifications-popover.data";

const props = defineProps<{
  notifications: {
    date: string,
    text: string
  }[],
}>()

const emit = defineEmits<{
  (e: 'handleNotificationsDialogOpen'): void
}>()

const activeTab = ref(TABS[0].value)
</script>

<style scoped lang="scss">
.notification-item {
  @apply flex gap-3 rounded-[10px] hover:bg-hoar/10 cursor-pointer;
  &:hover {
    & + .notification-item {
      hr {
        opacity: 0 !important;
      }
    }
  }
}
</style>
