<template>
  <app-dialog v-model="model" width="600" height="720">
    <template #header>
      <p class="text-14 font-semibold text-base">
        Уведомления <span class="text-hoar">18</span>
      </p>
    </template>
    <div class="flex flex-col gap-3 -mt-1">
      <app-tabs v-model="activeTab" :items="TABS" no-query />
      <div class="flex flex-col">
        <div v-for="(notification, index) in notifications" :key="index" class="notification-item group -mx-2">
          <div class="py-2">
          <span class="rounded-full w-9 h-9 flex justify-center items-center bg-primary ml-3">
            <img class="img--white !w-[18px] !h-[18px]" src="../../../../assets/icons/screencast.svg" alt="">
          </span>
          </div>
          <div class="flex flex-col w-full">
            <hr
              v-if="index > 0"
              class="-mr-5 mt-auto group-hover:opacity-0 border-lunar"
            />
            <div class="flex w-full justify-between pr-3 py-2">
              <div>
                <p class="text-11 font-medium text-hoar">
                  {{ notification.date }}
                </p>
                <p class="text-13 font-medium text-base line-clamp-3" v-html="notification.text" />
              </div>
              <div class="flex flex-col items-center justify-center shrink-0 relative">
                <img
                  class="img--hoar w-[18px] h-[18px] opacity-0 group-hover:opacity-100"
                  src="../../../../assets/icons/direction/arrow-right-simple.svg" alt=""
                >
                <span class="rounded-full w-1.5 h-1.5 bg-primary group-hover:opacity-0 absolute" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      @click="model = false"
      class="w-7 h-7 flex items-center justify-center rounded-full hover:bg-background bg-white absolute top-0 -right-10 duration-200"
    >
      <img class="img--hoar w-[14px] h-[14px]" src="@/assets/icons/close.svg" alt="">
    </button>
  </app-dialog>
</template>

<script setup lang="ts">
import { TABS } from "@/layouts/headers/components/notifications-popover/notifications-popover.data";
import AppTabs from "@/shared/UIKit/app-tabs/app-tabs.vue";
import AppDialog from "@/shared/UIKit/app-dialog/app-dialog.vue";
import { ref } from "vue";

const model = defineModel<boolean>()

const activeTab = ref(TABS[0].value)

const props = defineProps<{
  notifications: {
    date: string,
    text: string
  }[],
}>()
</script>

<style scoped lang="scss">
.notification-item {
  @apply flex gap-3 rounded-[10px] hover:bg-hoar/10 cursor-pointer;
  &:hover {
    & + .notification-item {
      hr {
        opacity: 0 !important;
      }
    }
  }
}
</style>
