import { Route } from "@/router/router.types";
import { Layout } from "@/layouts/layouts.types";
import { userRoutes } from "@/modules/user/router/user.routes";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";

export const routes = [
  ...userRoutes,
  {
    path: "",
    name: "",
    redirect: `/${Route.ORG_STRUCTURE}`,
  },
  // {
  //   path: `/${Route.CREATE_PROJECT}`,
  //   name: Route.CREATE_PROJECT,
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () =>
  //     import("@/views/create-project-view/create-project-view.vue"),
  // },
  {
    path: `/${Route.CREATE_PROJECT_SUCCESS}`,
    name: Route.CREATE_PROJECT_SUCCESS,
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/views/create-project-view/create-project-success.vue"),
  },

  {
    path: `/${Route.ACCESSES_KB}`,
    name: Route.ACCESSES_KB,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.ACCESSES],
    },
    component: () =>
      import("@/modules/accesses/views/knowledge-base/knowledge-base-view.vue"),
  },
  {
    path: `/${Route.ACCESSES}`,
    name: Route.ACCESSES,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.ACCESSES],
    },
    component: () =>
      import("@/modules/accesses/views/services/services-view.vue"),
  },
  {
    path: `/${Route.MAILINGS}`,
    name: Route.MAILINGS,
    meta: {
      title: "Рассылки",
      authRequired: true,
    },
    component: () => import("@/views/mailings/mailings-view.vue"),
  },

  {
    path: `/${Route.CLIENT_BASE}`,
    name: Route.CLIENT_BASE,
    meta: {
      title: "База клиентов",
      authRequired: true,
    },
    component: () => import("@/views/client-base/client-base-view.vue"),
  },

  {
    path: `/${Route.MAILINGS_CREATE}`,
    name: Route.MAILINGS_CREATE,
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/mailings/mailings-create.vue"),
  },

  {
    path: `/${Route.MAILINGS_EDIT}/:id`,
    name: Route.MAILINGS_EDIT,
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/mailings/mailings-edit.vue"),
  },

  {
    path: `/${Route.CRM}`,
    name: Route.CRM,
    meta: {
      title: "CRM",
      authRequired: true,
    },
    component: () => import("@/views/crm/crm-list-view.vue"),
  },

  {
    path: `/${Route.CRM}/:id`,
    name: `${Route.CRM}-current`,
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/crm/crm-view.vue"),
  },

  {
    path: `/${Route.CRM_LINKS}/:id`,
    name: Route.CRM_LINKS,
    meta: {
      layout: Layout.BLANK,
      authRequired: true,
    },
    component: () => import("@/views/crm/crm-links-view.vue"),
  },

  {
    path: `/${Route.CRM_CLIENT}`,
    name: Route.CRM_CLIENT,
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/crm/crm-client-view.vue"),
  },
  {
    path: `/${Route.CRM_TOUCHES}`,
    name: Route.CRM_TOUCHES,
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/crm/crm-touches-view.vue"),
  },

  {
    path: `/${Route.WORKFLOW}/:id`,
    name: Route.WORKFLOW,
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/modules/workflow/views/workflow/workflow-view.vue"),
  },
  {
    path: `/${Route.WORKFLOW}/:id/${Route.BOT_CHAT}`,
    name: Route.BOT_CHAT,
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/modules/workflow/views/chat-bot/chat-bot-view.vue"),
  },
  {
    path: `/${Route.BOTS}`,
    name: Route.BOTS,
    meta: {
      title: "Все боты",
      authRequired: true,
    },
    component: () => import("@/views/bots/bots-view.vue"),
  },
  {
    path: `/${Route.ORG_STRUCTURE}`,
    name: Route.ORG_STRUCTURE,
    meta: {
      title: "",
      authRequired: true,
      sidebars: [],
    },
    component: () => import("@/views/org-structure/org-flow-view.vue"),
  },
  {
    path: `/${Route.ORG_STRUCTURE_TEMPLATES}`,
    name: Route.ORG_STRUCTURE_TEMPLATES,
    meta: {
      title: "",
      authRequired: true,
      sidebars: [],
    },
    component: () =>
      import(
        "@/views/org-structure-templates/org-structure-templates-view.vue"
      ),
  },
  {
    path: `/${Route.ORG_STRUCTURE_TEMPLATE}/:id`,
    name: Route.ORG_STRUCTURE_TEMPLATE,
    meta: {
      title: "",
      authRequired: true,
      sidebars: [],
    },
    component: () => import("@/views/org-structure/org-flow-view.vue"),
  },
  {
    path: `/${Route.ORG_STRUCTURE_GRADING}`,
    name: Route.ORG_STRUCTURE_GRADING,
    meta: {
      title: "",
      authRequired: true,
    },
    component: () => import("@/views/org-structure/org-flow-view.vue"),
  },
  {
    path: `/${Route.ORG_STRUCTURE_CREATE}`,
    name: Route.ORG_STRUCTURE_CREATE,
    meta: {
      title: "",
      authRequired: true,
    },
    component: () =>
      import("@/views/org-structure/org-structure-create-view.vue"),
  },
  {
    path: `/${Route.ORG_STRUCTURE_EMPLOYEES}`,
    name: Route.ORG_STRUCTURE_EMPLOYEES,
    meta: {
      title: "",
      authRequired: true,
    },
    component: () =>
      import("@/views/org-structure/employees-table/employees-table.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE}`,
    name: Route.KNOWLEDGE_BASE,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/knowledge-base-tree/knowledge-base-tree-view.vue"
      ),
  },
  {
    path: `/${Route.KNOWLEDGE_BASE_ADMIN}`,
    name: Route.KNOWLEDGE_BASE_ADMIN,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/admin/views/knowledge-base-tree/admin-knowledge-base-tree-view.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_ARTICLE}/:id`,
    name: Route.KNOWLEDGE_BASE_ARTICLE,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/knowledge-base-article/knowledge-base-article-view.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING}`,
    name: Route.KNOWLEDGE_BASE_TESTING,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/testing.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_RESPONDERS}/:id`,
    name: `/${Route.KNOWLEDGE_BASE_TESTING_RESPONDERS}/:id`,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-responder.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_CREATE}`,
    name: Route.KNOWLEDGE_BASE_TESTING_CREATE,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_TESTING_CREATE,
      ],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-create.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_EDIT}/:id`,
    name: `Route.KNOWLEDGE_BASE_TESTING_EDIT/:id`,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_TESTING_EDIT,
      ],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/testing-edit.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_PREVIEW}`,
    name: Route.KNOWLEDGE_BASE_TESTING_PREVIEW,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-preview.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_RESULTS}/:id`,
    name: Route.KNOWLEDGE_BASE_TESTING_RESULTS,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-results.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_STATS}/:id`,
    name: Route.KNOWLEDGE_BASE_TESTING_STATS,
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/testing-stats.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_STATS}/:id/:userId`,
    name: `${Route.KNOWLEDGE_BASE_TESTING_STATS}/:id/:userId`,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-responder-result.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_START}/:id`,
    name: Route.KNOWLEDGE_BASE_TESTING_START,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/testing-start.vue"),
  },
  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_CHECKING}/:id/:userId`,
    name: Route.KNOWLEDGE_BASE_TESTING_CHECKING,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-checking.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY}`,
    name: Route.KNOWLEDGE_BASE_SURVEY,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey.vue"),
  },
  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_STATS}/:id`,
    name: Route.KNOWLEDGE_BASE_SURVEY_STATS,
    meta: {
      authRequired: true,
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey-stats.vue"),
  },
  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_STATS}/:id/:userId`,
    name: `/${Route.KNOWLEDGE_BASE_SURVEY_STATS}/:id/:userId`,
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/survey-responder-result.vue"
      ),
  },
  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_CREATE}`,
    name: Route.KNOWLEDGE_BASE_SURVEY_CREATE,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_SURVEY_CREATE,
      ],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey-create.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_EDIT}/:id`,
    name: `Route.KNOWLEDGE_BASE_SURVEY_EDIT/:id`,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_SURVEY_EDIT,
      ],
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey-edit.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_PREVIEW}`,
    name: Route.KNOWLEDGE_BASE_SURVEY_PREVIEW,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/survey-preview.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_START}/:id`,
    name: Route.KNOWLEDGE_BASE_SURVEY_START,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import("@/modules/knowledge-base/views/testing-survey/survey-start.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_RESULTS}/:id`,
    name: Route.KNOWLEDGE_BASE_SURVEY_RESULTS,
    meta: {
      authRequired: true,
      layout: "empty",
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/survey-results.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_TEMPLATES}`,
    name: Route.KNOWLEDGE_BASE_SURVEY_TEMPLATES,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/survey-templates.vue"
      ),
  },

  {
    path: `/${Route.HIRING}`,
    name: Route.HIRING,
    meta: {
      title: "Найм сотрудников",
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/knowledge-base/views/hiring/hiring-view.vue"),
  },

  {
    path: `/${Route.VACANCY_RESULTS}/:id`,
    name: Route.VACANCY_RESULTS,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/knowledge-base/views/hiring/vacancy-results-view.vue"),
  },
  {
    path: `/${Route.VACANCY_RESULTS}/:id/:userId`,
    name: `/${Route.VACANCY_RESULTS}/:id`,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import("@/modules/knowledge-base/views/hiring/vacancy-results-results-view.vue"),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_TEMPLATES}`,
    name: Route.KNOWLEDGE_BASE_TESTING_TEMPLATES,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.KNOWLEDGE_BASE],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-templates.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_TEMPLATES_CREATE}`,
    name: Route.KNOWLEDGE_BASE_TESTING_TEMPLATES_CREATE,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_TESTING_TEMPLATE_CREATE,
      ],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-templates-create.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_TESTING_TEMPLATES_EDIT}/:id`,
    name: Route.KNOWLEDGE_BASE_TESTING_TEMPLATES_EDIT,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_TESTING_TEMPLATE_EDIT,
      ],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/testing-templates-edit.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_TEMPLATES_CREATE}`,
    name: Route.KNOWLEDGE_BASE_SURVEY_TEMPLATES_CREATE,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_SURVEY_TEMPLATE_CREATE,
      ],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/survey-templates-create.vue"
      ),
  },

  {
    path: `/${Route.KNOWLEDGE_BASE_SURVEY_TEMPLATES_EDIT}/:id`,
    name: Route.KNOWLEDGE_BASE_SURVEY_TEMPLATES_EDIT,
    meta: {
      authRequired: true,
      sidebars: [
        SidebarType.KNOWLEDGE_BASE,
        SidebarType.KNOWLEDGE_BASE_SURVEY_TEMPLATE_EDIT,
      ],
    },
    component: () =>
      import(
        "@/modules/knowledge-base/views/testing-survey/survey-templates-edit.vue"
      ),
  },

  {
    path: `/${Route.BUSINESS_PROCESS}`,
    meta: {
      authRequired: true,
      sidebars: [],
    },
    name: Route.BUSINESS_PROCESS,
    component: () => import("@/modules/business-process/business-process.vue"),
  },

  {
    path: `/${Route.BUSINESS_PROCESS_CREATE}`,
    name: Route.BUSINESS_PROCESS_CREATE,
    meta: {
      authRequired: true,
      sidebars: [],
    },
    component: () =>
      import(
        "@/modules/business-process/views/common/business-process-create.vue"
      ),
  },

  {
    path: `/${Route.BUSINESS_PROCESS_CREATE}/:templateId`,
    name: `${Route.BUSINESS_PROCESS_CREATE}/:templateId`,
    meta: {
      authRequired: true,
      sidebars: [],
    },
    component: () =>
      import(
        "@/modules/business-process/views/common/business-process-create.vue"
        ),
  },

  {
    path: `/${Route.BUSINESS_PROCESS_TEMPLATE_CREATE}`,
    name: Route.BUSINESS_PROCESS_TEMPLATE_CREATE,
    meta: {
      authRequired: true,
      sidebars: [],
    },
    component: () =>
      import(
        "@/modules/business-process/views/template/business-process-template-create.vue"
      ),
  },

  {
    path: `/${Route.BUSINESS_PROCESS_TEMPLATE_EDIT}/:id`,
    name: Route.BUSINESS_PROCESS_TEMPLATE_EDIT,
    meta: {
      authRequired: true,
      sidebars: [],
    },
    component: () =>
      import(
        "@/modules/business-process/views/template/business-process-template-edit.vue"
      ),
  },

  {
    path: `/${Route.BUSINESS_PROCESS_EDIT}/:id`,
    name: Route.BUSINESS_PROCESS_EDIT,
    meta: {
      authRequired: true,
      sidebars: [],
    },
    component: () =>
      import(
        "@/modules/business-process/views/common/business-process-edit.vue"
      ),
  },
  {
    path: `/${Route.BUSINESS_PROCESS_VIEW}/:id`,
    name: Route.BUSINESS_PROCESS_VIEW,
    meta: {
      authRequired: true,
      sidebars: [],
    },
    component: () =>
      import("@/modules/business-process/business-process-view.vue"),
  },
  {
    path: `/${Route.USER_PROFILE}`,
    name: Route.USER_PROFILE,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.NONE],
    },
    component: () =>
      import(
        "@/modules/user-cabinet/views/profile/user-profile/user-profile-view.vue"
      ),
  },
  {
    path: `/${Route.PROJECTS}`,
    name: Route.PROJECTS,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.USER_CABINET],
    },
    component: () =>
      import("@/modules/user-cabinet/views/projects/projects-view.vue"),
  },
  {
    path: `/${Route.ADMIN_PROFILE}`,
    name: Route.ADMIN_PROFILE,
    meta: {
      authRequired: true,
      sidebars: [SidebarType.USER_CABINET],
    },
    component: () =>
      import(
        "@/modules/user-cabinet/views/profile/admin-profile/admin-profile-view.vue"
      ),
  },
];
