export enum Route {
  AUTH = "auth",
  AUTH_CONFIRM_TOKEN = "auth/confirm/token",
  REGISTER = "register",
  RESET = "reset",
  NEW_PASSWORD = "new-password/token",
  CONFIRM_PHONE = "confirm-phone",
  INVITATION_CONFIRM = "invitation-confirm/token",

  CREATE_PROJECT = "create-project",
  CREATE_PROJECT_SUCCESS = "create-project/success",

  MAILINGS = "mailings",
  MAILINGS_CREATE = "mailings/create",
  MAILINGS_EDIT = "mailings/edit",
  MAILINGS_PENDING = "mailings/pending",
  MAILINGS_SUCCESS = "mailings/success",

  CRM = "crm",
  CRM_LINKS = "crm/links",
  CRM_CLIENT = "crm/client",
  CRM_TOUCHES = "crm/touches",

  WORKFLOW = "workflow",
  BOT_CHAT = "bot-chat",

  BOTS = "bots",

  CLIENT_BASE = "client-base",

  ORG_STRUCTURE = "org-structure",
  ORG_STRUCTURE_CREATE = "org-structure/create",
  ORG_STRUCTURE_TEMPLATES = "org-structure-templates",
  ORG_STRUCTURE_TEMPLATE = "org-structure-template",
  ORG_STRUCTURE_GRADING = "org-structure/grading",
  ORG_STRUCTURE_EMPLOYEES = "org-structure/employees",

  ACCESSES = "accesses",
  ACCESSES_KB = "kb",

  KNOWLEDGE_BASE = "knowledge-base",
  KNOWLEDGE_BASE_ADMIN = "knowledge-base-adminbb",

  KNOWLEDGE_BASE_ARTICLE = "knowledge-base/article",

  KNOWLEDGE_BASE_TESTING = "knowledge-base/testing",
  KNOWLEDGE_BASE_TESTING_CREATE = "knowledge-base/testing/create",
  KNOWLEDGE_BASE_TESTING_EDIT = "knowledge-base/testing/edit",
  KNOWLEDGE_BASE_TESTING_PREVIEW = "knowledge-base/testing/preview",
  KNOWLEDGE_BASE_TESTING_START = "knowledge-base/testing/start",
  KNOWLEDGE_BASE_TESTING_RESULTS = "knowledge-base/testing/results",
  KNOWLEDGE_BASE_TESTING_RESPONDERS = "knowledge-base/testing/responders",
  KNOWLEDGE_BASE_TESTING_STATS = "knowledge-base/testing/stats",
  KNOWLEDGE_BASE_TESTING_CHECKING = "knowledge-base/testing/checking",

  KNOWLEDGE_BASE_TESTING_TEMPLATES = "knowledge-base/testing/templates",
  KNOWLEDGE_BASE_TESTING_TEMPLATES_CREATE = "knowledge-base/testing/templates/create",
  KNOWLEDGE_BASE_TESTING_TEMPLATES_EDIT = "knowledge-base/testing/templates/edit",

  KNOWLEDGE_BASE_SURVEY = "knowledge-base/survey",
  KNOWLEDGE_BASE_SURVEY_CREATE = "knowledge-base/survey/create",
  KNOWLEDGE_BASE_SURVEY_EDIT = "knowledge-base/survey/edit",
  KNOWLEDGE_BASE_SURVEY_PREVIEW = "knowledge-base/survey/preview",
  KNOWLEDGE_BASE_SURVEY_START = "knowledge-base/survey/start",
  KNOWLEDGE_BASE_SURVEY_RESULTS = "knowledge-base/survey/results",
  KNOWLEDGE_BASE_SURVEY_STATS = "knowledge-base/survey/stats",

  KNOWLEDGE_BASE_SURVEY_TEMPLATES = "knowledge-base/survey/templates",
  KNOWLEDGE_BASE_SURVEY_TEMPLATES_CREATE = "knowledge-base/survey/templates/create",
  KNOWLEDGE_BASE_SURVEY_TEMPLATES_EDIT = "knowledge-base/survey/templates/edit",

  BUSINESS_PROCESS = "business-process",
  BUSINESS_PROCESS_CREATE = "business-process/create",
  BUSINESS_PROCESS_TEMPLATE_CREATE = "business-process/template/create",
  BUSINESS_PROCESS_TEMPLATE_EDIT = "business-process/template/edit",

  BUSINESS_PROCESS_EDIT = "business-process/edit",
  BUSINESS_PROCESS_VIEW = "business-process/view",

  HIRING = "hiring",
  VACANCY_RESULTS = "vacancy-results",

  USER_PROFILE = "profile",
  PROJECTS = "projects",

  ADMIN_PROFILE = "admin-profile",
}

export enum Theme {
  DARK = "dark",
  light = "light",
}
