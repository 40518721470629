<template>
  <app-dialog v-model="balanceDialogStore.isOpen" width="400">
    <template #header> Пополнение баланса</template>
    <app-input v-model="sum" label="Сумма пополнения" type="number" name="sum" />
    <template #footer>
      <app-button
        :loading="isLoading"
        class="w-full"
        type="texted"
        variant="primary"
        @click="handleTopUpBalanceBtnClick"
      >
        Пополнить
      </app-button>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import AppDialog from "@/shared/UIKit/app-dialog/app-dialog.vue";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import AppInput from "@/shared/UIKit/app-input.vue";
import { paymentApi } from "@/api";
import { useBalanceDialogStore } from "@/stores/dialogs/balance-dialog";
import * as Yup from "yup";
import { useForm } from 'vee-validate';
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { SnackbarStatus } from "@/stores/snackbar/snackbar.types";

const sum = ref(0);
const isLoading = ref(false);
const balanceDialogStore = useBalanceDialogStore();
const { showSnackbar } = useSnackbarStore()

const MIN = 10
const MAX = 300000

const { handleSubmit } = useForm({
  validationSchema: Yup.object({
    sum: Yup.number().min(MIN, `Минимальная сумма пополнения — ${MIN} рублей.`)
      .max(MAX, `Максимальная сумма пополнения — ${MAX} рублей.`).required(),
  }),
});

const handleTopUpBalanceBtnClick = handleSubmit(async () => {
  try {
    isLoading.value = true;
    const response = await paymentApi.initTinkoffPayment({ amount: sum.value });
    window.open(response.paymentUrl, "blank");
  } catch (e) {
    showSnackbar('Не удалось перейти на сайт оплаты.', SnackbarStatus.ERROR)
  } finally {
    isLoading.value = false;
    balanceDialogStore.isOpen = false;
  }

})

watch(() => balanceDialogStore.isOpen, val => {
  if (!val) {
    sum.value = 0
  }
})
</script>

<style lang="scss" scoped></style>
