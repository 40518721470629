<template>
  <navigation-layout title="Навигация">
    <template #default>
      <div class="m-1.5 flex grow flex-col gap-1 overflow-y-auto">
        <app-navigation-item
          v-for="item in NAVIGATION_ITEMS"
          :key="item.title"
          :item="item"
        />
      </div>
    </template>

    <template #footer>
      <router-link class="flex items-center gap-2" to="">
        <img alt="" class="img--primary" src="@/assets/icons/message.svg" />
        <span class="text-13 font-medium text-primary">Тех. поддержка</span>
      </router-link>
    </template>

    <template #sidebars>
      <knowledge-base-sidebar
        v-if="
          sidebarStore.currentSidebars[
            sidebarStore.currentSidebars.length - 1
          ] === SidebarType.KNOWLEDGE_BASE
        "
      />
      <testing-create-sidebar />
      <accesses-sidebar
        v-if="
          sidebarStore.currentSidebars[
            sidebarStore.currentSidebars.length - 1
          ] === SidebarType.ACCESSES
        "
      />
      <testing-edit-sidebar />
      <cabinet-sidebar />
      <survey-create-sidebar />
      <survey-edit-sidebar />
      <survey-create-template-sidebar />
      <survey-edit-template-sidebar />
      <testing-create-template-sidebar />
      <testing-edit-template-sidebar />
    </template>
  </navigation-layout>
</template>

<script lang="ts" setup>
import NavigationLayout from "@/layouts/navigation/navigation-layout.vue";
import { NAVIGATION_ITEMS } from "@/layouts/navigation/navigation.data";
import AppNavigationItem from "@/layouts/navigation/app-navigation-item.vue";
import KnowledgeBaseSidebar from "@/layouts/sidebars/knowledge-base-sidebar.vue";
import TestingCreateSidebar from "@/layouts/sidebars/knowledge-base-create-sidebar/testing-create-sidebar.vue";
import CabinetSidebar from "@/layouts/sidebars/cabinet-sidebar/cabinet-sidebar.vue";
import SurveyCreateSidebar from "@/layouts/sidebars/knowledge-base-create-sidebar/survey-create-sidebar.vue";
import TestingEditSidebar from "@/layouts/sidebars/knowledge-base-create-sidebar/testing-edit-sidebar.vue";
import SurveyEditSidebar from "@/layouts/sidebars/knowledge-base-create-sidebar/survey-edit-sidebar.vue";
import SurveyCreateTemplateSidebar from "@/layouts/sidebars/knowledge-base-create-sidebar/survey-create-template-sidebar.vue";
import SurveyEditTemplateSidebar from "@/layouts/sidebars/knowledge-base-create-sidebar/survey-edit-template-sidebar.vue";
import TestingCreateTemplateSidebar from "@/layouts/sidebars/knowledge-base-create-sidebar/testing-create-template-sidebar.vue";
import TestingEditTemplateSidebar from "@/layouts/sidebars/knowledge-base-create-sidebar/testing-edit-template-sidebar.vue";
import AccessesSidebar from "@/layouts/sidebars/accesses-sidebar/accesses-sidebar.vue";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import { useSidebarStore } from "@/stores/sidebar/sidebar";

const sidebarStore = useSidebarStore();
</script>

<style lang="scss" scoped></style>
