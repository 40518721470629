<template>
  <div
    class="app-tabs flex h-9 min-h-9 items-center rounded-[10px] bg-hoar/10 px-0.5"
  >
    <button
      v-for="(item, i) in items"
      :key="item.value"
      :class="
        !noQuery
          ? [
              item.value !== route.query[queryName]
                ? 'app-tabs__unselected'
                : 'app-tabs__selected',
            ]
          : [
              model !== item.value
                ? 'app-tabs__unselected'
                : 'app-tabs__selected',
            ]
      "
      class="h-8 flex-1 rounded-[10px] px-3"
      @click="handleTabClick(item.value)"
    >
      <span class="text-13 font-semibold whitespace-nowrap">
        {{ item.text }}
      </span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import type { TabItem } from "@/shared/UIKit/app-tabs/app-tabs.types";
import { useRoute } from "vue-router";

export interface Props {
  items: TabItem[];
  size?: "small" | "tiny";
  queryName?: string;
  noQuery?: boolean;
}

const model = defineModel<string>();
const props = withDefaults(defineProps<Props>(), {
  size: "small",
  queryName: "",
  noQuery: false,
});
const emit = defineEmits<{
  (e: "change", value: string): void;
}>();

const route = useRoute();
const handleTabClick = (val: string) => {
  emit("change", val);
  model.value = val;
};
</script>

<style lang="scss" scoped>
.app-tabs {
  &__unselected {
    @apply bg-transparent text-hoar opacity-80;

    span {
      @apply opacity-80 hover:opacity-100;
    }
  }

  &__selected {
    @apply bg-white shadow-misty-veil;
  }
}
</style>
