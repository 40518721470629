<template>
  <router-link
    :class="{ 'bg-hoar/[0.07]': route.query.id === entity.baseId }"
    :to="{ name: route.name, query: { id: entity.baseId } }"
    class="flex h-9 w-full items-center gap-2 rounded-[10px] px-2.5 hover:bg-hoar/10 active:bg-hoar/15"
    v-bind="$attrs"
  >
    <img
      v-if="canCollapse"
      :class="{
        '!img--primary !rotate-0': isCollapsed,
      }"
      alt=""
      class="navigation-item--toggle img--hoar flex h-[18px] w-[18px] shrink-0 -rotate-90 cursor-pointer duration-200"
      src="@/assets/icons/direction/arrow-circle.svg"
      @click.prevent="isCollapsed = !isCollapsed"
    />
    <img
      v-if="entity.parentVariant"
      alt=""
      class="img--hoar"
      src="@/assets/icons/user/user.svg"
    />
    <img
      v-if="entity.variant === NodeVariant.DIVISION"
      alt=""
      class="img--hoar"
      src="@/assets/icons/user/user-double.svg"
    />
    <img
      v-if="entity.variant === NodeVariant.DEPARTMENT"
      alt=""
      class="img--hoar"
      src="@/assets/icons/house.svg"
    />
    <span class="text-14 font-medium">{{ entity.title }} </span>

    <img
      v-if="
        accessesStore.permissions?.[entity.baseId] &&
        accessesStore.permissions[entity.baseId].permissions.length
      "
      alt=""
      class="img--hoar ml-auto"
      src="@/assets/icons/star-circle.svg"
    />
  </router-link>
  <collapse
    v-if="entity.positions && entity.positions.length"
    :when="isCollapsed"
  >
    <org-entity
      v-for="position in entity.positions"
      :class="{ '!pl-[62px]': entity.variant === NodeVariant.DIVISION }"
      :entity="position"
      class="!pl-9"
    />
  </collapse>
  <collapse
    v-if="entity.divisions && entity.divisions.length"
    :when="isCollapsed"
  >
    <org-entity
      v-for="division in entity.divisions"
      :entity="division"
      class="!pl-9"
    />
  </collapse>
</template>

<script lang="ts" setup>
import { Collapse } from "vue-collapsed";
import {
  type FilterPositionsResponse,
  NodeVariant,
} from "@boostbiz/org_client/schemas";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useAccessesStore } from "@/stores/accesses/accesses-store";

interface Props {
  entity: FilterPositionsResponse;
}

const props = defineProps<Props>();
const accessesStore = useAccessesStore();
const route = useRoute();
const isCollapsed = ref(false);

const canCollapse = computed(() => {
  if (props.entity.parentVariant) {
    return false;
  }

  if (props.entity.variant === NodeVariant.DEPARTMENT) {
    return (
      (props.entity.divisions && props.entity.divisions.length) ||
      props.entity.positions.length
    );
  }

  return props.entity.positions.length;
});
</script>

<style lang="scss" scoped></style>
