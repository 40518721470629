import { computed, nextTick, ref } from "vue";
import type { Edge, Node } from "@vue-flow/core";
import { MarkerType } from "@vue-flow/core";
import {
  type IAddNode,
  NodeVariant,
  type OrgFlowSettings,
  type OrgFlowStore,
  type OrgFlowStoreActions,
  type OrgSidebar,
} from "@/stores/org-structure/org-flow.types";
import { defineStore } from "pinia";
import {
  createDepartment,
  createDivision,
  createEmployee,
  createGrade,
  createPosition,
} from "@/views/org-structure/utils/utils";
import { AccessSection } from "@/views/org-structure/org-structure.data";
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { useDeleteDialogStore } from "@/stores/delete-dialog";
import { nanoid } from "nanoid";
import { useLayout } from "@/composables/use-layout";
import { orgStructureApi } from "@/api";
import type { IOption } from "@/shared/UIKit/app-select/app-select.types";
import { useProjectsStore } from "@/stores/projects/projects";
import { useRoute } from "vue-router";
import { Route } from "@/router/router.types";

export const useOrgFlowStore = defineStore(
  "org",
  (): OrgFlowStore & OrgFlowStoreActions => {
    const { showSnackbar } = useSnackbarStore();
    const { confirm } = useDeleteDialogStore();
    const { layout } = useLayout();
    const route = useRoute();
    const nodes = ref<Node[]>([]);
    const edges = ref<Edge[]>([]);
    const projectsStore = useProjectsStore();
    const orgStructureId = ref("");

    const updateLayout = () => {
      nextTick(() => {
        nodes.value = layout(nodes.value, edges.value, "TB");
      });
    };

    const getOrgStructureId = async () => {
      if (!projectsStore.selectedProjectId) {
        await projectsStore.getProjects();
      }

      orgStructureId.value =
        route.name !== Route.ORG_STRUCTURE_TEMPLATE
          ? projectsStore.projects.find(
              (item) => item.id === projectsStore.selectedProjectId,
            )?.orgStructureId || ""
          : String(route.params.id);
    };

    const getTree = async () => {
      getOrgStructureId();

      const response = await orgStructureApi.getFullOrgStructure(
        orgStructureId.value,
      );

      nodes.value.length = 0;
      edges.value.length = 0;

      const responseNodes = response.nodes as Node[];

      nodes.value.push(responseNodes[0]);

      for (const node of responseNodes.slice(1)) {
        addNode({
          type: node.type,
          data: node.data,
          parentId: nodes.value[0].id,
          nodeId: node.id,
        });
      }

      updateLayout();
    };

    const settings: OrgFlowSettings = {
      isMetricsShown: true,
      isFunctionsShown: true,
      areProductsShown: true,
      isAccessesShown: true,
    };

    // const displaySettings: OrgFlowDisplaySettings[] = [
    //   OrgFlowDisplaySettings.ACCESSES,
    //   OrgFlowDisplaySettings.FUNCTIONS,
    //   OrgFlowDisplaySettings.METRICS,
    //   OrgFlowDisplaySettings.PRODUCTS,
    // ];

    const departmentsOptions = computed((): IOption[] => {
      return nodes.value
        .filter(
          (item) =>
            item.type === NodeVariant.DEPARTMENT ||
            item.type === NodeVariant.EMPLOYEE,
        )
        .map((item) => ({
          title: item.data.title
            ? item.data.title
            : "Управленческий департамент",
          value: item.data.baseId,
        }));
    });

    const currentSidebar = ref<OrgSidebar>({
      variant: [],
      department: createDepartment(),
      division: createDivision(),
      employee: createEmployee(),
      position: createPosition(),
      grade: createGrade(),
      node: {},
      isEditable: false,
      access: {
        isOpen: false,
        data: {
          title: AccessSection.KNOWLEDGE_BASE,
          items: [],
        },
      },
      open(variant) {
        this.variant.push(variant);
      },
      close(variant) {
        this.variant = this.variant.filter((item) => item !== variant);
      },
      reset() {
        this.variant = [];
        this.isEditable = false;
      },
    });

    const addNode = ({
      type,
      parentId,
      data,
      nodeId = nanoid(10),
    }: IAddNode) => {
      nodes.value.push({
        id: nodeId,
        type,
        position: {
          x: 0,
          y: 0,
        },
        data: {
          ...data,
          nodeId,
        },
      });

      edges.value.push({
        id: `e${parentId}-e${nodeId}`,
        source: parentId,
        target: nodeId,
        type: "base",
        style: { stroke: "#E3E9F5", strokeWidth: 2 },
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#E6E6E6",
          height: 40,
          width: 16,
          strokeWidth: 1,
        },
        data: {
          sourcePosition: "bottom",
          targetPosition: "top",
        },
      });
    };

    const deleteElement = async (
      id: string,
      name: string,
      type: "node" | "entity" = "node",
      variant?: NodeVariant,
    ) => {
      try {
        confirm(name, async (callback: any) => {
          const isDeleteConfirmed = await callback;

          if (isDeleteConfirmed) {
            if (type === "node") {
              await orgStructureApi.deleteViewNode(id);
            } else {
              switch (variant) {
                case NodeVariant.EMPLOYEE:
                  await orgStructureApi.deleteEmployee(id);
                  break;
                case NodeVariant.DIVISION:
                  await orgStructureApi.deleteDivision(id);
                  break;
                case NodeVariant.DEPARTMENT:
                  await orgStructureApi.deleteDepartment(id);
              }
            }

            await getTree();
            currentSidebar.value.reset();
            showSnackbar("Успешно удалено");
          }
        });
      } catch (e) {}
    };

    return {
      updateLayout,
      getOrgStructureId,
      nodes,
      edges,
      deleteElement,
      addNode,
      settings,
      currentSidebar,
      orgStructureId,
      departmentsOptions,
      getTree,
    };
  },
);
