import type { IGrade } from "@/views/org-structure/org-structure.data";
import { nanoid } from "nanoid";
import { NodeVariant } from "@/stores/org-structure/org-flow.types";
import {
  DepartmentDTO,
  type DivisionDTO,
  EmployeeDTO,
  EmployeeStatus,
  PositionDTO,
} from "@boostbiz/org_client/schemas";

// ДОБАВЛЯТЬ АЙДИШКИ РОДИТЕЛЕЙ НЕ ИЗ НОД, А ИЗ DATA

export const createPosition = (): PositionDTO => ({
  isLead: false,
  baseId: nanoid(),
  title: "",
  parentVariant: NodeVariant.EMPLOYEE,
  productName: "",
  parentId: "",
});

export const createEmployee = (
  divisionId = "",
  departmentId = "",
  nodeId = "",
): EmployeeDTO => {
  return {
    baseId: nanoid(10),
    id: nodeId,
    middleName: "",
    firstName: "",
    secondName: "",
    telegram: "",
    productName: "",
    status: EmployeeStatus.PROBATION,
    city: "",
    contractType: "",
    birthDate: "",
    startDate: "",
    departmentId,
    divisionId,
    email: "",
    positionId: "",
    position: createPosition(),
    variant: NodeVariant.EMPLOYEE,
    // other: [
    //   {
    //     title: OtherSection.PROJECT,
    //     items: ["Подготовка реального проекта"],
    //   },
    //   {
    //     title: OtherSection.METRICS,
    //     items: ["Адаптивы выбора"],
    //   },
    //   {
    //     title: OtherSection.FUNCTIONS,
    //     items: ["Лотерея исходного кода"],
    //   },
    // ],
    // accesses: [
    //   {
    //     title: AccessSection.SERVICE,
    //     items: ["Отдел разработчиков"],
    //   },
    //   {
    //     title: AccessSection.KNOWLEDGE_BASE,
    //     items: [],
    //   },
    // ],
  };
};

export const createDivision = (departmentId = ""): DivisionDTO => {
  const baseId = nanoid(10);

  return {
    baseId,
    productName: "",
    title: "Новый отдел " + baseId,
    departmentId,
    employees: [],
    variant: NodeVariant.DIVISION,
    positions: [],
    // accesses: [
    //   {
    //     title: AccessSection.SERVICE,
    //     items: ["Отдел смарчков"],
    //   },
    //   {
    //     title: AccessSection.KNOWLEDGE_BASE,
    //     items: [],
    //   },
    // ],
  };
};

export const createDepartment = (baseId = ""): DepartmentDTO => {
  return {
    baseId,
    divisionsColumns: [],
    employees: [],
    positions: [],
    productName: "",
    title: "Новый департамент " + baseId,
    variant: NodeVariant.DEPARTMENT,
    // accesses: [
    //   {
    //     title: AccessSection.SERVICE,
    //     items: [],
    //   },
    //   {
    //     title: AccessSection.KNOWLEDGE_BASE,
    //     items: ["Департамент сайто и плашко"],
    //   },
    // ],
  };
};

export const createGrade = (): IGrade => {
  const id = nanoid(3);
  return {
    id,
    positionId: "b79a5998-8847-4365-a1ac-f013a72efa7c",
    name: "Стажер",
    minSalary: 5000,
    maxSalary: 10000,
    product: "Продукт",
    skills: "Навык",
    metrics: [],
    functions: [],
  };
};
