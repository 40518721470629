<template>
  <transition-group
    class="absolute left-1/2 top-0 flex w-[400px] -translate-x-1/2 translate-y-[24px] flex-col items-center gap-3 z-[101]"
    name="list"
    tag="ul"
  >
    <div
      v-for="snackbar in snackbarStore.snackbars"
      :key="snackbar.id"
      :class="[`snackbar--${snackbar.status}`]"
      class="snackbar"
    >
      <img
        :src="getSnackbarIcon(snackbar.status)"
        alt=""
        class="img--white h-5 w-5"
      />
      <span class="text-13 font-medium text-white">{{ snackbar.text }}</span>
      <!--      <button-->
      <!--        class="!min-h-4 !min-w-4 dark:hidden"-->
      <!--        @click="snackbarStore.closeSnackbar(snackbar.id)"-->
      <!--      >-->
      <!--        <img alt="X" class="img&#45;&#45;white" src="@/assets/icons/close.svg" />-->
      <!--      </button>-->
    </div>
  </transition-group>
</template>

<script lang="ts" setup>
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { SnackbarStatus } from "@/stores/snackbar/snackbar.types";
import tickIcon from "@/assets/icons/tick/tick-circle-filled.svg";
import dangerIcon from "@/assets/icons/danger.svg";
import dangerCircleIcon from "@/assets/icons/danger-circle.svg";

const snackbarStore = useSnackbarStore();

const getSnackbarIcon = (status: SnackbarStatus) => {
  switch (status) {
    case SnackbarStatus.SUCCESS:
      return tickIcon;
    case SnackbarStatus.WARN:
      return dangerIcon;
    case SnackbarStatus.ERROR:
      return dangerCircleIcon;
    case SnackbarStatus.INFO:
      return dangerCircleIcon;
  }
};
</script>

<style lang="scss" scoped>
.snackbar {
  @apply flex h-10 items-center gap-2 rounded-[10px] px-3;

  &--success {
    @apply bg-toxic;
  }

  &--error {
    @apply bg-tomato;
  }

  &--info {
    @apply bg-base;
  }

  &--warn {
    @apply bg-orange;
  }
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.list-leave-active {
  position: absolute;
}
</style>
