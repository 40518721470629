<template>
  <navigation-layout
    :level="1"
    class="translate-x-0 duration-200"
    is-sub-layout
    @click-title="router.push(`/${Route.ORG_STRUCTURE}`)"
  >
    <template #header>
      <app-button
        class="absolute left-2.5 top-1/2 w-10 -translate-y-1/2 !p-0"
        icon
        size="small"
        type="texted"
        variant="tertiary"
        @click="handleBackClick"
      >
        <img
          alt=""
          class="img--hoar !h-5 !w-5"
          src="../../../assets/icons/direction/arrow-right-long.svg"
        />
      </app-button>
      <router-link :to="`/${Route.ACCESSES}`" class="text-16 font-medium"
        >Доступы
      </router-link>
    </template>

    <div class="flex flex-col gap-[2px] p-[6px]">
      <router-link
        v-for="route in routes"
        :key="route.title"
        :to="route.to"
        active-class="bg-hoar/10 !text-base group"
        class="flex h-9 w-full items-center gap-2 rounded-[10px] px-2.5 py-2 text-hoar hover:bg-hoar/10 active:bg-hoar/15"
        exact
        exact-active-class="bg-hoar/10 !text-base group"
      >
        <img
          :src="route.icon"
          alt=""
          class="img--hoar group-[]:img--primary h-[18px] w-[18px]"
        />
        <p class="text-14 font-semibold">{{ route.title }}</p>
      </router-link>
    </div>
    <div class="h-px w-full bg-lunar" />
    <div class="p-1.5">
      <org-entity v-for="entity in accessesStore.entities" :entity="entity" />
    </div>
  </navigation-layout>
</template>
<script lang="ts" setup>
import notebookOpenIcon from "@/assets/icons/notebook-open.svg";
import layersIcon from "@/assets/icons/layers.svg";
import { Route } from "@/router/router.types";
import { ref } from "vue";
import NavigationLayout from "@/layouts/navigation/navigation-layout.vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import { useRoute, useRouter } from "vue-router";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { useAccessesStore } from "@/stores/accesses/accesses-store";
import OrgEntity from "@/layouts/sidebars/accesses-sidebar/org-entity.vue";

const sidebarStore = useSidebarStore();
const accessesStore = useAccessesStore();
const router = useRouter();
const route = useRoute();

const handleBackClick = () => {
  sidebarStore.goBack();
  router.push({ name: Route.ORG_STRUCTURE });
};

const routes = ref([
  {
    title: "Сервис",
    icon: layersIcon,
    to: {
      name: Route.ACCESSES,
      query: {
        id: route.query.id,
      },
    },
  },
  {
    title: "База знаний",
    icon: notebookOpenIcon,
    to: {
      name: Route.ACCESSES_KB,
      query: {
        id: route.query.id,
      },
    },
  },
]);
</script>

<style lang="scss" scoped></style>
