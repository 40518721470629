import { ref } from "vue";
import dagre from "@dagrejs/dagre";

export function useLayout() {
  const graph = ref(new dagre.graphlib.Graph());

  function layout(nodes, edges, direction) {
    const dagreGraph = new dagre.graphlib.Graph();

    graph.value = dagreGraph;

    dagreGraph.setDefaultEdgeLabel(() => ({}));

    dagreGraph.setGraph({
      rankdir: direction,
      // nodesep: 200,
      ranksep: 200,
      // align: "DL",
    });

    for (const node of nodes) {
      dagreGraph.setNode(node.id, {
        // width: graphNode.dimensions.width || 500,
        width: document.querySelector(`[data-id='${node.id}']`).clientWidth,
        // height: graphNode.dimensions.height || 150,
        height: document.querySelector(`[data-id='${node.id}']`).clientHeight,
      });
    }

    for (const edge of edges) {
      dagreGraph.setEdge(edge.source, edge.target);
    }

    dagre.layout(dagreGraph);

    const rankHeight: Record<number, number> = {};

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id) as any;

      if (
        !rankHeight[nodeWithPosition.rank] ||
        rankHeight[nodeWithPosition.rank] < nodeWithPosition.height
      ) {
        rankHeight[nodeWithPosition.rank] = nodeWithPosition.height;
      }
    });

    return nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id) as any;
      const nodeElement = document.querySelector(`[data-id='${node.id}']`);

      return {
        ...node,
        position: {
          x: nodeWithPosition.x - nodeElement.clientWidth / 2,
          y: nodeWithPosition.y - rankHeight[nodeWithPosition.rank] / 2,
        },
      };
    });
  }

  return { graph, layout };
}
