import { defineStore } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { authApi, orgStructureApi } from "@/api";
import { ref } from "vue";
import type { FilterPositionsResponse } from "@boostbiz/org_client/schemas";
import { useOrgFlowStore } from "@/stores/org-structure/org-flow";
import type { PermissionRecord } from "@boostbiz/auth/schemas/index.schema";
import { useRoute, useRouter } from "vue-router";

export const useAccessesStore = defineStore("accesses", () => {
  const { showSnackbar } = useSnackbarStore();
  const entities = ref<FilterPositionsResponse[]>([]);
  const orgFlowStore = useOrgFlowStore();
  const router = useRouter();
  const route = useRoute();
  const permissions = ref<PermissionRecord | null>(null);

  const getPermissions = async () => {
    permissions.value = await authApi.common.getAllPermission();
  };

  const getOrgStructureEntities = async () => {
    await orgFlowStore.getOrgStructureId();

    entities.value = await orgStructureApi.filterPositions(
      orgFlowStore.orgStructureId,
      "",
      false,
    );

    router.replace({
      name: route.name,
      query: {
        id: entities.value[0].baseId,
      },
    });
  };

  return {
    getOrgStructureEntities,
    entities,
    permissions,
    getPermissions,
  };
});
