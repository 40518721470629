<template>
  <app-popover v-model="isNotificationPopoverOpen" placement="bottom" arrow :offset="12">
    <app-button
      size="medium"
      type="half-contained"
      variant="tertiary"
      icon
      class="relative flex h-9 w-9 items-center justify-center rounded-[10px]"
    >
      <img alt="" class="img--hoar !w-[18px] !h-[18px]" src="../../../../assets/icons/bell-outline.svg" />
      <span
        class="top absolute -right-[7px] -top-[3px] h-4 rounded-full bg-primary px-[5px] text-11 font-semibold text-white"
      >{{ notifications.length }}</span>
    </app-button>
    <template #content="{ close }">
      <notifications-preview
        v-show="!isFullListOpen"
        :notifications="notifications"
        @handleOpenFullList="isFullListOpen = true"
      />
      <notifications-full-list
        v-show="isFullListOpen"
        :notifications="notifications"
        @handleNotificationsDialogOpen="handleNotificationsDialogOpen(close)"
      />
    </template>
    <template #arrow>
      <div class="border-t border-l border-lunar bg-white w-2 h-2 rotate-45 -translate-y-1" />
    </template>
  </app-popover>
  <notifications-dialog v-model="isNotificationsDialogOpen" :notifications="notifications" />
</template>

<script setup lang="ts">
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import AppPopover from "@/shared/UIKit/app-popover.vue";
import { ref, watch } from 'vue'
import NotificationsPreview from "@/layouts/headers/components/notifications-popover/notifications-preview.vue";
import NotificationsFullList from "@/layouts/headers/components/notifications-popover/notifications-full-list.vue";
import NotificationsDialog from "@/layouts/headers/components/notifications-popover/notifications-dialog.vue";

const isNotificationPopoverOpen = ref(false);
const isFullListOpen = ref(false)
const isNotificationsDialogOpen = ref(false)
const notifications = ref([
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол, ЦКР завершен! Ознакомьтесь с результатами работы команды. Рабочий стол, ЦКР завершен! Ознакомьтесь с результатами работы команды. Рабочий стол, ЦКР завершен! Ознакомьтесь с результатами работы команды'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: 'Рабочий стол'
  },
  {
    date: 'Вчера в 12:42',
    text: '<b>ЦКР завершен!</b> Ознакомьтесь с <br /> результатами работы команды'
  },
])

const handleNotificationsDialogOpen = (callback: () => void) => {
  callback();
  isNotificationsDialogOpen.value = true
  isFullListOpen.value = false
}

watch(isNotificationPopoverOpen, val => {
  if (!val) {
    isFullListOpen.value = false
  }
})
</script>
