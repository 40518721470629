import type { IComboboxOption } from "@/shared/UIKit/app-select/app-select.types";
import type { NodeVariant } from "@/stores/org-structure/org-flow.types";
import { AccessEnum } from "@/views/org-structure/org-structure.types";
import {
  type DivisionColumnDTO,
  EmployeeDTO,
} from "@boostbiz/org_client/schemas";

export const ORG_STRUCTURE_ID = "e821059e-47f8-4ea6-b1af-b787380f35b3";
export const PROJECT_ID = "160";

export enum AccessSection {
  SERVICE = "Сервис",
  KNOWLEDGE_BASE = "База знаний",
}

export enum OtherSection {
  PROJECT = "Проект",
  METRICS = "Метрики",
  FUNCTIONS = "Функции",
}

export interface SidebarInfoCard<T> {
  title: T;
  items: string[];
}

export interface IEmployee {
  id: string;
  departmentId: string;
  divisionId: string;
  firstName: string;
  secondName: string;
  middleName: string;
  telegram: string;
  email: string;
  birthDate: string;
  startDate: string;
  position: IComboboxOption;
  variant: NodeVariant;
  accesses: SidebarInfoCard<AccessSection>[];
  productName: string;
  other: SidebarInfoCard<OtherSection>[];
}

export interface IDivision {
  id: string;
  departmentId: string;
  title: string;
  employees: EmployeeDTO[];
  productName: string;
  variant: NodeVariant;
  accesses: SidebarInfoCard<AccessSection>[];
}

export interface IDivisionColumn {
  id: string;
  divisions: IDivision[];
}

export interface IDepartment {
  id: string;
  title: string;
  employees: EmployeeDTO[];
  divisions: DivisionColumnDTO[];
  productName: string;
  variant: NodeVariant;
  accesses: SidebarInfoCard<AccessSection>[];
}

export interface IGrade {
  id: string;
  name: string;
  positionId: string;
  minSalary: number;
  maxSalary: number;
  product: string;
  skills: string;
  metrics: string[];
  functions: string[];
}

export interface AccessData {
  title: string;
  id: number;
  accesses: AccessEnum[];
  departments?: {
    title: string;
    id: number;
    parentId: number;
    accesses: AccessEnum[];
    divisions?: {
      title: string;
      id: number;
      accesses: AccessEnum[];
      parentId: number;
    }[];
  }[];
}

export const ACCESS_DATA: AccessData = {
  title: "Оргстуктура",
  accesses: [AccessEnum.VIEW, AccessEnum.EDIT, AccessEnum.DELETE],
  id: 1,
  departments: [
    {
      title: "Административный департамент",
      id: 2,
      accesses: [AccessEnum.VIEW, AccessEnum.EDIT],
      parentId: 1,
      divisions: [
        {
          title: "Отдел сопровождения",
          accesses: [],
          id: 3,
          parentId: 2,
        },
        {
          title: "Отдел финансов",
          id: 4,
          accesses: [AccessEnum.VIEW, AccessEnum.EDIT],
          parentId: 2,
        },
      ],
    },
    {
      id: 5,
      title: "Департамент аналитики",
      accesses: [AccessEnum.VIEW, AccessEnum.EDIT, AccessEnum.DELETE],
      parentId: 1,
      divisions: [
        {
          title: "Отдел тестирования",
          id: 6,
          accesses: [AccessEnum.VIEW, AccessEnum.EDIT, AccessEnum.DELETE],
          parentId: 5,
        },
      ],
    },
  ],
};
