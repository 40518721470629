import type { ComputedRef, Ref } from "vue";
import type { Edge, Node } from "@vue-flow/core";
import type {
  AccessSection,
  IGrade,
  SidebarInfoCard,
} from "@/views/org-structure/org-structure.data";
import type {
  DepartmentDTO,
  DivisionDTO,
  EmployeeDTO,
  OrgNodeViewDTO,
  PositionDTO,
} from "@boostbiz/org_client/schemas";
import type { IOption } from "@/shared/UIKit/app-select/app-select.types";

export interface IAddNode {
  type: NodeVariant;
  parentId: string;
  nodeId?: string;
  data: DivisionDTO | EmployeeDTO | DepartmentDTO;
}

export enum NodeVariant {
  DEPARTMENT = "department",
  DIVISION = "division",
  EMPLOYEE = "employee",
}

export enum OrgSidebarVariant {
  DEPARTMENT = "department",
  ADD_EMPLOYEE = "add-employee",
  EMPLOYEE = "employee",
  DIVISION = "division",
  ADD_GRADE = "create-grade",
  GRADE = "grade",
  GRADE_LIST = "grade_list",
  POSITION = "position",
}

export interface OrgFlowSettings {
  isMetricsShown: boolean;
  isFunctionsShown: boolean;
  areProductsShown: boolean;
  isAccessesShown: boolean;
}

export enum OrgFlowDisplaySettings {
  METRICS = "Метрики",
  FUNCTIONS = "Функции",
  PRODUCTS = "Продукты",
  ACCESSES = "Доступы",
}

export interface DeleteDialog {
  isOpen: boolean;
  id: string;
  variant: NodeVariant | null;
}

export interface OrgSidebar {
  variant: OrgSidebarVariant[];
  node: OrgNodeViewDTO;
  position: PositionDTO;
  employee: EmployeeDTO;
  division: DivisionDTO;
  department: DepartmentDTO;
  grade: IGrade;
  isEditable: boolean;
  reset: () => void;
  open: (variant: OrgSidebarVariant) => void;
  close: (variant: OrgSidebarVariant) => void;
  access: {
    isOpen: boolean;
    data: SidebarInfoCard<AccessSection>;
  };
}

export interface OrgFlowStore {
  settings: OrgFlowSettings;
  nodes: Ref<Node<any, any, string>[]>;
  edges: Ref<Edge<any, any, string>[]>;
  currentSidebar: Ref<OrgSidebar>;
  orgStructureId: Ref<string>;
  departmentsOptions: ComputedRef<IOption[]>;
}

export interface OrgFlowStoreActions {
  getOrgStructureId: ()=>Promise<void>;
  getTree: () => Promise<void>;
  addNode: (data: IAddNode) => void;
  updateLayout: () => void;
  deleteElement: (
    id: string,
    name: string,
    type?: "node" | "entity",
    variant?: NodeVariant,
  ) => void;
}
