export const TABS = [
  {
    text: 'Все',
    value: 'All'
  },
  {
    text: 'Непрочитанные',
    value: 'Unread'
  },
  {
    text: 'Системные',
    value: 'System'
  },
]
